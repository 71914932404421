import http from "@/helpers/http";
import { pack } from "@/helpers/utils";
const service = "faUnidrinkOrder/";

export function getOrderList(form) {
  return http.request({
    url: "/SYTdknLasJ.php/unidrink/order/index",
    method: "get",
    params: {
      addtabs: 1,
      _: Date.now(),
      ...form,
    },
  });
}

export function listByPage(form) {
	return http.request({
		url: service + "listByPage",
		method: "POST",
		params: {
			page: form.currPage,
			pageSize: form.pageSize,
		},
		data: pack(form)
	})
}

export function listSaleData(form) {
	return http.request({
		url: service + "listSaleData",
		method: "POST",
		params: {
			page: form.currPage,
			pageSize: form.pageSize,
		},
		data: pack(form)
	})
}

export function listChartData(form) {
	return http.request({
		url: service + "listChartData",
		method: "POST",
		params: {
			page: form.currPage,
			pageSize: form.pageSize,
		},
		data: pack(form)
	})
}

export function listRevenueData(form) {
	return http.request({
		url: service + "listRevenueData",
		method: "POST",
		params: {
			page: form.currPage,
			pageSize: form.pageSize,
		},
		data: pack(form)
	})
}

export function listSummarySaleData(form) {
	return http.request({
		url: service + "listSummarySaleData",
		method: "POST",
		params: {
			page: form.currPage,
			pageSize: form.pageSize,
		},
		data: pack(form)
	})
}

export function genBill(form) {
	return http.request({
		url: service + "genBill",
		method: "POST",
		data: pack(form)
	})
}
